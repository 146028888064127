.team {
    width: 100%;
    z-index: 2;
}

.team #wave-bottom {
    position: relative;
    bottom: 10px;
    z-index: 1;
}

.team-container {
    padding-top: 20px;
    width: calc(100%);  
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 120px;
    background-color: #428bca;
}

.team::before {
    content:'';
    background-color: #428bca;
}

.team .top {
    width: 1344px;
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}


.team .top h1 {
    font-size: 36px;
}

.team .bottom {
    width: 1344px;
    height: auto;
    display: grid;
  grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    margin-top: 20px;
    gap: 0px;
    
}

.team .bottom .team-card {
    scale: 0.85;
    border-radius: 25px;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 600px;
    width: 300px;
}

.team .bottom .team-card .member-photo {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 15px;
}

.team .bottom .team-card:hover,
.team .bottom .ceo-card:hover {
    animation:  shadowHoverAnimation 0.8s forwards
}

.team  .view-btn {
    padding: 10px;
    border-radius: 10px;
    color: #428bca;
    font-weight: 600;
    background-color: white;

}

@keyframes shadowHoverAnimation {
    0% {
        box-shadow: none;
    }
    
    100% {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    }
}

/* MEDIA QUERIES */
/* QuickSearch = ( team-media ) */

@media (max-width: 1600px) {
    .team .top,
    .team .bottom {
        width: calc(100% - 240px);
    }
}

@media (max-width: 1460px) {
    .team .bottom {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr); 
    }
}

@media (max-width: 1184px) {
    .team .bottom {
        width: calc(100% - 120px);
    }
}

@media (max-width: 1040px) {
    .team .bottom {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr); 
    }
}

@media (max-width: 1015px) {
    .team .bottom {
        width: calc(100% - 120px);
    }
}

@media (max-width: 820px) {
    .team .bottom {
        width: calc(100% - 30px);
    } 
}

@media (max-width: 485px) {
    .team .top {
        width: 100%;
    }
}